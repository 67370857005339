<template>
  <div class="home">
    <div class="box" v-if="info">
      <template v-for="item in info">
        <h3 class="hc"><b>{{item.hc}}</b></h3>
        <template v-if="item.title">
          <table class="title" width="100%" border="0" style="margin-bottom: 8px;font-size:16px;">
            <tbody>
              <tr>
                <td>
                  <!-- <img :src="item.title.img" style="width: 80px;height: 80px;"> -->
                </td>
                <td align="left"><b>{{item.title.name}}</b></td>
              </tr>
            </tbody>
          </table>
          <table width="100%" border="1" cellspacing="0">
            <tbody>
              <tr v-for="it in item.body">
                <td class="col-xs-4" align="right">{{it.name}}</td>
                <td class="col-xs-8">{{it.value}}</td>
              </tr>
            </tbody>
          </table>
        </template>
        <template v-else>
          <table width="100%" border="0">
            <tbody>
              <tr v-for="it in item.body">
                <td class="col-xs-4" align="right">{{it.name}}</td>
                <td class="col-xs-8">{{it.value}}</td>
              </tr>
            </tbody>
          </table>
        </template>
      </template>
    </div>
  </div>
</template>
<script>
import quality from './json/quality.json'
import system from './json/system.json'
import certificate01 from './json/certificate01.json'
import certificate02 from './json/certificate02.json'
import certificate03 from './json/certificate03.json'
export default {
  data() {
    return {
      info:null
    }
  },
  created() {
    let type = this.$route.query.type
    let infoData
    if(type == 'quality'){
      infoData = quality
    }
    if(type == 'system'){
      infoData = system
    }
    if(type == 'certificate01'){
      infoData = certificate01
    }
    if(type == 'certificate02'){
      infoData = certificate02
    }
    if(type == 'certificate03'){
      infoData = certificate03
    }
    // infoData.map( item => {
    //   if(item.title){
    //     item.title['img'] = require('./img/'+item.title.img)
    //   }
    // })
    this.info = infoData
  }
}
</script>
<style lang="scss">
  body{
    background: #999999;
  }
  .home {
    display: flex;
    justify-content: center;
    // min-height: 100vh;
    .box {
      width: 100%;
      margin-left: 0%;
      margin-right: 0%;
      overflow: auto;
      max-width: 772px;
      font-size: 16px;
      line-height: 30px;
      background: #FFFFFF;
      padding: 10px 20px;
      border-radius: 10px;
      td {
        padding: 0px 5px 0px 15px;
      }
      .hc {
        text-align: left;
        width: 100%;
        box-sizing: border-box;
        border-bottom: 1px solid #999999;
      }

      .title {
        td {
          text-align: left;
        }
      }
    }

    .col-xs-4 {
      width: 30%;
    }

    .col-xs-8 {
      text-align: left;
      width: 70%;
    }
  }
</style>
